.wrapperBody {
  padding: 0 24px;
}

.wrapperTitle {
  background: #f5f5f5;
  width: 100%;
  padding: 4px 24px;
}

.wrapperTitle p {
  margin: 0;
  font-weight: 700;
  font-size: 14px;
}
