.wrapperCount {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 8px 16px;
  gap: 8px;
  background: #eeeeee;
  border-radius: 50px;
  width: fit-content;
}

.wrapperCount p {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
}
