.wrapper {
  display: flex;
  flex-direction: column;
}

.wrapperFilter {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.wrapperFilterItem {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
}

.wrapperHeader {
  border-radius: 16px 16px 0px 0px;
  background: var(--neutral-50, #fff);
  display: flex;
  padding: 16px 24px;
  align-self: stretch;
  gap: 12px;
  flex-direction: column;
}

.textFilter {
  color: var(--brand-color-primary-blue, #11beff);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.wrapperRangePicker {
  display: flex;
  align-items: center;
  gap: 8px;
}

.wrapperRangePicker p {
  color: var(--neutral-800, #424242);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.wrapperBody {
  background: var(
    --GradientCCCPortal,
    linear-gradient(270deg, #9957c2 0%, #36a0ee 100%)
  );
  min-height: 70vh;
  padding: 24px;
  display: grid;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Creates four equal-width columns */
  grid-gap: 24px; /* Adjust the gap between items as needed */
}

.wrapperBodyEmpty {
  background: var(
    --GradientCCCPortal,
    linear-gradient(270deg, #9957c2 0%, #36a0ee 100%)
  );
  min-height: 70vh;
  padding: 24px;
  display: flex;
}

.wrapperEmpty {
  background: #ffffff3d;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 24px;
  height: auto;
  width: 100%;
  justify-content: center;
  color: #ffffff;
  font-weight: 500;
}

.wrapperCardPerformance,
.wrapperCardPerformanceZero {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;

  /* Shadow/Large */
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05),
    0px 5px 14px 0px rgba(0, 0, 0, 0.05);
  height: fit-content;
}

.wrapperCardPerformanceZero {
  grid-column: span 2;
}

.headerCardPerformance,
.headerCardPerformanceZero {
  border-radius: 8px 8px 0 0;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--other-hover, #e8faff);
  color: var(--neutral-700, #616161);
}

.headerCardPerformanceZero {
  background: var(--other-pending, #eca722);
  color: var(--neutral-50, #fff);
}

.textHeader {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.bodyCardPerformance {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  background: var(--neutral-50, #fff);
  border-radius: 0 0 8px 8px;
  /* justify-content: space-between; */
}

.wrapperContentBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.wrapperItemContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.labelItemContent {
  color: var(--neutral-600, #757575);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.valueItemContent {
  color: var(--neutral-800, #424242);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.lineContent {
  background: var(--neutral-200, #eee);
  height: 1px;
  width: 100%;
  border: none;
}

.wrapperLinkDetail {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  cursor: pointer;
}

.detailTextLink,
.detailIconLink {
  color: var(--brand-color-secondary-blue, #1890ff);
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  margin: 0;
}

.detailIconLink {
  font-size: 12px;
}

.disableFilterProduct {
  color: #bdbdbd !important;
  cursor: not-allowed !important;
}

.wrapperFilterProduct {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  color: #1890ff;
}

.textFilterProduct {
  margin: 0;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
}
