.wrapperFilter {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.filterColumn {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.actionFilter {
  width: 100%;
  display: flex;
  gap: 24px;
  justify-content: flex-end;
  align-items: center;
}

.successWaybil {
  margin: 0;
  display: flex;
  gap: 10px;
  align-items: center;
  color: #00ab44;
  font-size: 14px;
}

.wrapperInputScan {
  display: flex;
  align-items: flex-end;
  gap: 12px;
  width: 100%;
}

.checkboxBarcode {
  margin-bottom: 9px;
  display: flex;
  align-items: center;
}
