.wrapperContent {
  display: flex;
  flex-direction: column;
  min-width: 170px;
}

.wrapperBody {
  padding: 8px;
}

.wrapperFooter {
  border-top: 1px solid #e0e0e0;
  padding: 8px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.wrapperFooter p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.wrapperFooter p:nth-child(1) {
  color: #ea1a1a;
}

.wrapperFooter p:nth-child(2) {
  color: #1890ff;
}
