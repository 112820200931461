.titleSection {
  color: #11c0ff;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.wrapperTitleSection {
  display: flex;
  gap: 16px;
  align-items: center;
}

.wrapperCardMaterialContent {
  border-radius: 0 0 12px 12px;
}

.wrapperContent {
  padding: 0 36px 0 36px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.buttonAddCustom {
  border-radius: 10px;
  border: 1px dashed #bababa;
  background: #fff;

  display: flex;
  padding: 18px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  cursor: pointer;
}

.textButton {
  color: var(--Black, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.wrapperItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
}

.itemFormatReport {
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Neutral-300, #e0e0e0);
  background: var(--Neutral-100, #fafafa);
  cursor: pointer;
}

.itemFormatReportActive {
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Other-Success, #00ab44);
  background: rgba(0, 171, 68, 0.08);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.wrapperItemFormat {
  display: flex;
  align-items: center;
  gap: 8px;
}

.itemFormatName {
  color: var(--Neutral-800, #424242);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.itemFormatStatus {
  color: var(--Other-Danger, #ea1a1a);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.itemFormatStatusActive {
  color: var(--Other-Success, #00ab44);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
