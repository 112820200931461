.wrapperFilter {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.wrapperForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.filterColumn {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.actionFilter {
  width: 100%;
  display: flex;
  gap: 24px;
  justify-content: flex-end;
  align-items: center;
}

.wrapperSelectField {
  width: 100%;
  position: relative;
}

.textAddKodeRetur {
  color: #1890ff;
  text-decoration-line: underline;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.wrapperDateField {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}
