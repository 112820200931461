.wrapperRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 40px;
  margin-top: 24px;
}

.wrapperColumn {
  display: flex;
  flex-basis: 100%;
  flex: 1;
  flex-direction: column;
  gap: 24px;
}

.wrapperAction {
  display: flex;
  gap: 24px;
  margin-top: 24px;
  justify-content: flex-end;
}
