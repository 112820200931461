.wrapperTree {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.wrapperHeader {
  background: #f6f7f9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 24px;
}

.labelHeader {
  font-weight: 600;
  margin: 0;
}

.wrapperContentParent {
  display: flex;
  gap: 0px;
}

.wrapperContent {
  display: flex;
  gap: 16px;
}
