.NotFound-bg {
    /* The image used */
    background-image: url(../../image/bg-NotFound.png);

    /* Full height */
    height: 100%;
    width: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}