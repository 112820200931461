.wrapperActionLink {
  display: flex;
  gap: 16px;
  align-items: center;
}

.wrapperActionLinkDisabled p:nth-child(1) {
  color: #00000040 !important;
  cursor: not-allowed !important;
}

.wrapperActionLink p:nth-child(1) {
  color: #1890ff;
  cursor: pointer;
}

.wrapperActionLink p {
  margin: 0;
  font-weight: 700;
}
