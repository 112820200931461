.wrapperFilter {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.wrapperItemFilter {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.filterColumn {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.actionFilter {
  width: 100%;
  display: flex;
  gap: 24px;
  justify-content: flex-end;
  align-items: center;
}

.actionWidth {
  width: 124px;
}
