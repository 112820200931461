.wrapperCount {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 16px;
}

.wrapperCountContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 32px 0;
  width: 100%;
}

.sectionCount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 32px;
  gap: 16px;
  border-radius: 10px;
}

.wrapperIcon {
  border-radius: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconCount {
  color: #ffffff;
  font-size: 21px;
}

.wrapperAction {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 16px;
}

.textCount {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}
