.wrapper {
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.buttonAction {
  align-self: flex-end;
}
