.wrapperFilter {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.filterColumn {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.alertInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.actionFilter {
  width: 100%;
  display: flex;
  gap: 24px;
  justify-content: flex-end;
  align-items: center;
}

.successWaybil {
  margin: 0;
  display: flex;
  gap: 10px;
  align-items: center;
  color: #00ab44;
  font-size: 16px;
}

.errorWaybil {
  margin: 0;
  display: flex;
  gap: 10px;
  align-items: center;
  color: #ea1a1a;
  font-size: 16px;
}

.wrapperInputScan {
  display: flex;
  align-items: flex-end;
  gap: 12px;
  width: 100%;
}

.checkboxBarcode {
  margin-bottom: 9px;
  display: flex;
  align-items: center;
}

/* PILL SCAN DOWNLOAD */
.wrapperPillScan {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  font-size: 14px;
  color: var(--neutral-50, #fff);
  width: fit-content;
  font-weight: 600;
  cursor: pointer;
}

.labelPill {
  text-decoration: underline;
}

.wrapperPill {
  display: flex;
  gap: 24px;
}

.wrapperPopPill {
  display: flex;
  gap: 8px;
  align-items: center;
}

.wrapperPopPill p {
  margin: 0;
  color: var(--other-pending, #eca722);
  font-size: 14px;
  font-weight: 700;
}

/* PILL SCAN DOWNLOAD */
