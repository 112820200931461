.wrapperAction {
  font-size: 14px;
  font-weight: 700;
  display: flex;
  gap: 8px;
  width: fit-content;
}

.wrapperActionLink {
  text-decoration: underline;
}

.wrapperAction p {
  margin: 0;
  cursor: pointer;
}

.actionDisabled p {
  color: #e0e0e0 !important;
  cursor: not-allowed;
}

.labelAction {
  color: #11beff;
}

.wrapperRefresh {
  display: flex;
  gap: 16px;
  align-items: center;
}

.wrapperWaybill {
  display: flex;
  gap: 24px;
}

.wrapperWaybill p {
  margin: 0;
  font-size: 14px;
  font-weight: 700;
}

.wrapperWaybill p:nth-child(1) {
  color: #0096ee;
}
