.wrapperWarning {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--Other-Info, #fcc642);
  background: rgba(252, 198, 66, 0.1);
}

.wrapperContentWarning {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}

.wrapperContentWarning p {
  margin-bottom: 0px;
}

.titleWarning {
  font-size: 14px;
  font-weight: 600;
}

.iconWarning {
  color: #fcc642;
  font-size: 16px;
}
