.wrapperModal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.iconModal {
  font-size: 40px;
  color: #ea1a1a;
}

.titleModal {
  font-weight: 600;
  font-size: 22px;
  color: #ea1a1a;
  margin: 0;
}

.descModal {
  color: #616161;
  font-size: 16px;
  text-align: center;
  margin: 0;
  max-width: 400px;
}

.wrapperAction {
  display: flex;
  gap: 32px;
  align-items: center;
}
