.wrapperContent {
  padding: 24px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}

.titleContent {
  font-size: 16px;
  color: #212121;
  margin-bottom: 0;
}

.infoData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  background: #fafafa;
  border-radius: 12px;
  width: 100%;
}

.bottomInfo {
  color: #757575;
  margin-bottom: 0;
}

.wrapperContentInfo {
  display: flex;
  width: 100%;
}

.labelContentInfo {
  color: #212121;
  margin-bottom: 0;
  flex: 1;
}

.valueContentInfo {
  font-weight: 600;
  color: #212121;
  margin-bottom: 0;
  flex: 1;
}
