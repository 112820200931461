.wrapperContent {
  padding: 24px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: flex-end;
}

.wrapperSelectField {
  width: 100%;
  position: relative;
}

.textAddKodeRetur {
  color: #1890ff;
  text-decoration-line: underline;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}
