.wrapperContent {
  padding: 24px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: flex-end;
  width: 100%;
}

.wrapperSelectField {
  width: 100%;
}

.wrapperAction {
  display: flex;
  gap: 16px;
}

.tableList {
  max-height: 500px;
  overflow: scroll;
}
