.textDesc {
  color: #ea1a1a;
}

.wrapperAction {
  display: flex;
  justify-content: flex-end;
}

.widthButton {
  width: 180px;
  align-self: flex-end;
}
